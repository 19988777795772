//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from "vuedraggable";
import FormDesignRender from '@/page/admin/approval-process/component/form/FormDesignRender'
import componentMinxins from '../ComponentMinxins'

export default {
  mixins: [componentMinxins],
  name: "SpanLayout",
  components: {draggable, FormDesignRender},
  props: {
    value:{
      default: null
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    _items: {
      get() {
        return this.items;
      },
      set(val) {
        this.items = val;
      }
    },
    __items() {
      let result = []
      for (let i = 0; i < this.items.length; i++) {
        if (i > 0 && i % 2 > 0) {
          result.push([this.items[i - 1], this.items[i]])
        }
      }
      if (result.length * 2 < this.items.length) {
        result.push([this.items[this.items.length - 1]])
      }
      return result
    },
    selectFormItem: {
      get() {
        return this.$store.state.selectFormItem
      },
      set(val) {
        this.$store.state.selectFormItem = val
      },
    },
    nodeMap() {
      return this.$store.state.nodeMap
    }
  },
  data() {
    return {
      select: null,
      drag: false,
      formConfig: {
        //数据字段
        data: {},
        //校验规则
        rules: {}
      },
      form: {
        formId: '',
        formName: "",
        logo: {},
        formItems: [],
        process: {},
        remark: ""
      }
    }
  },
  methods: {
    selectItem(cp) {
      this.selectFormItem = cp
    },
    getSelectedClass(cp) {
      return this.selectFormItem && this.selectFormItem.id === cp.id ?
          'border-left: 4px solid #f56c6c' : ''
    },
    delItem(index) {
      this.$confirm('删除组件将会连带删除包含该组件的条件以及相关设置，是否继续?', '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      }).then(() => {
        if (this._items[index].name === 'SpanLayout') {
          //删除的是分栏则遍历删除分栏内所有子组件
          this._items[index].props.items.forEach(item => {
            this.removeFormItemAbout(item)
          })
          this._items[index].props.items.length = 0
        } else {
          this.removeFormItemAbout(this._items[index])
        }
        this._items.splice(index, 1)
      })
    },
    async removeFormItemAbout(item) {
      this.nodeMap.forEach(node => {
        //搜寻条件，进行移除
        if (node.type === 'CONDITION') {
          node.props.groups.forEach(group => {
            let i = group.cids.remove(item.id)
            if (i > -1) {
              //从子条件移除
              group.conditions.splice(i, 1)
            }
          })
        }
        //搜寻权限，进行移除
        if (node.type === 'ROOT' || node.type === 'APPROVAL' || node.type === 'CC') {
          node.props.formPerms.removeByKey('id', item.id)
        }
      })
    },
  }
}
